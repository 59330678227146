import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/order/api/v1/order'

export default {
  //管理端获取订单详情
  getStaffSearch(payload) {
    return axios.get(`${baseUrl}/detail/admin?orderId=${payload}`)
  },
  //管理端订单列表
  getOrderList(payload) {
    return axios.post(`${baseUrl}/list/admin?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`, payload)
  }
}
